import * as React from "react";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";

import "./UpcomingEvents.css";

import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

const Bold = ({ children }) => <span className="bold">{children}</span>;
const Text = ({ children }) => <p>{children}</p>;

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    // [BLOCKS.EMBEDDED_ASSET]: (node) => {
    //   return (
    //     <>
    //       <h2>Embedded Asset</h2>
    //       <pre>
    //         <code>{JSON.stringify(node, null, 2)}</code>
    //       </pre>
    //     </>
    //   );
    // },
    "embedded-asset-block": (node) => {
      const { gatsbyImageData } = node.data.target;
      const image = getImage(gatsbyImageData);

      if (!gatsbyImageData) {
        // asset is not an image
        return null;
      }
      return <GatsbyImage placeholder="blurred" image={image} />;
    },
  },
  renderText: (text) => {
    return text.split("\n").reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  },
};

// markup
const RecentPage = ({ data }) => {
  const {
    title,
    body,
    image,
    dates,
    buttonTitle,
    buttonLink,
    includeButton,
    isThereAnUpcomingEvent,
  } = data.contentfulUpcomingPage;
  const eventImage = getImage(image.gatsbyImageData);

  console.log(body);
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Upcoming • System of Systems</title>
      </Helmet>
      {isThereAnUpcomingEvent ? (
        <div className="_2across gridCentered topOfPage">
          <p className="upcomingEventDates">{dates}</p>
          <h1 className="upcomingEventTitle">{title}</h1>
          <GatsbyImage
            className="upcomingEventPicture"
            placeholder="blurred"
            image={eventImage}
          />
          <div className="upcomingEventBody">
            {body && renderRichText(body, options)}
            {includeButton && (
              <a className="upcomingButton" href={buttonLink}>
                {buttonTitle}
              </a>
            )}
          </div>
        </div>
      ) : (
        <p>No upcoming events</p>
      )}
    </Layout>
  );
};

export const query = graphql`
  {
    contentfulUpcomingPage {
      image {
        gatsbyImageData
      }
      title
      dates
      body {
        raw
      }
      buttonTitle
      buttonLink
      includeButton
      isThereAnUpcomingEvent
    }
  }
`;

export default RecentPage;
